import dayjs from "dayjs";

import redDotIconPng from "assets/img/map-icon-dot-red.png";
import yellowDotIconPng from "assets/img/map-icon-dot-yellow.png";
import blueDotIconPng from "assets/img/map-icon-dot-blue.png";
import greenDotIconPng from "assets/img/map-icon-dot-green.png";
import grayDotIconPng from "assets/img/map-icon-dot-gray.png";
import unknownMarker from "assets/img/map-icon-dot-unknown.png";

import redLocationMarker from "assets/img/map-icon-curr-red.png";
import yellowLocationMarker from "assets/img/map-icon-curr-yellow.png";
import blueLocationMarker from "assets/img/map-icon-curr-blue.png";
import greenLocationMarker from "assets/img/map-icon-curr-green.png";
import grayLocationMarker from "assets/img/map-icon-curr-gray.png";
import unknownLocationMarker from "assets/img/map-icon-curr-unknown.png";

import {
  ipv4Address,
  letterNumberUnderscoreRegex,
  letterNumberUnderscoreNoSpaceRegex,
  min8Length,
  oneLowerCase,
  oneNumber,
  oneSpecialLetter,
  oneUpperCase,
  onlyStartWithLetterRegex,
  USER_TYPE,
  onlyStartWithLetterNumberRegex,
  COLUMN_TYPE,
  SORT_DIRECTION,
} from "./constants";

export const LEVEL_COLOR = {
  GREEN: "#339933",
  BLUE: "#83CBEB",
  RED: "#FF5B5B",
  YELLOW: "#bfa500",
  GRAY: "#AEAEAE",
};

const signalLevel = [
  {
    min: 0,
    max: 0,
    color: LEVEL_COLOR.GRAY,
  },
  {
    min: -84,
    max: -1,
    color: LEVEL_COLOR.GREEN,
  },
  {
    min: -100,
    max: -85,
    color: LEVEL_COLOR.BLUE,
  },
  {
    min: -114,
    max: -101,
    color: LEVEL_COLOR.YELLOW,
  },
  {
    min: -139,
    max: -115,
    color: LEVEL_COLOR.RED,
  },
];

export const getSignalStrengthColor = (strength) => {
  const level = signalLevel.find(({ min, max }) => strength >= min && strength <= max);
  return level?.color;
};

export const convertColumnId = (columnId) => {
  if (columnId === "eiSerialNumber") return "Sn";
  if (columnId === "imei") return "Imei";
  if (columnId === "phoneNumber") return "Ph";
  return "";
};

export const getDateString = (date) => (dayjs.isDayjs(date) ? date.format("L LT") : dayjs(date).format("L LT"));

export const convertIpAddress = (value) => {
  let newValue = value;

  if (newValue.includes("_")) newValue = newValue.replaceAll("_", ".");

  // if not ipv4 address
  if (!ipv4Address.test(newValue)) newValue = "";

  return newValue;
};

export const convertCognitoAttr = (user) => {
  const data = {};
  if (user) {
    user.forEach(({ Name, Value }) => {
      if (Name === "given_name") {
        data.roleName = Value;
        data.isAdmin = Value === USER_TYPE.ADMIN;
        data.isPowerUser = Value === USER_TYPE.POWER_USER;
      } else if (Name === "preferred_username") {
        data.organizationName = Value;
      } else {
        data[Name] = Value;
      }
    });
  }
  return data;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, "");

  // Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};

export const nameValidationCheck = (name, _other, noSpace = false, allowFirstDigit = false) => {
  let errorText = "";

  if (name === "") {
    return true;
  }

  if (!noSpace && !letterNumberUnderscoreRegex.test(name))
    errorText += "You may use only alphanumeric characters, spaces, underscores, and hyphens.\n";

  if (noSpace && !letterNumberUnderscoreNoSpaceRegex.test(name))
    errorText += "You may use only alphanumeric characters, underscores, and hyphens.\n";

  if (!allowFirstDigit && !onlyStartWithLetterRegex.test(name))
    errorText += "The first character of the name must be a letter of the alphabet";

  if (allowFirstDigit && !onlyStartWithLetterNumberRegex.test(name))
    errorText += "The first character of the name must be number or letter of the alphabet";

  return errorText.length > 0 ? errorText : true;
};

export const passwordStrengthCheck = (value) => {
  let errorText = "";
  if (!min8Length.test(value)) errorText += "Password length must be at least 8!\n";
  if (!oneUpperCase.test(value)) errorText += "Password must includes at least one uppercase!\n";
  if (!oneLowerCase.test(value)) errorText += "Password must includes at least one lowercase!\n";
  if (!oneSpecialLetter.test(value)) errorText += "Password must includes at least one special character!\n";
  if (!oneNumber.test(value)) errorText += "Password must includes at least one number!\n";
  return errorText.length > 0 ? errorText : true;
};

export const getColorMarker = (color) => {
  switch (color) {
    case LEVEL_COLOR.GREEN:
      return greenLocationMarker;
    case LEVEL_COLOR.RED:
      return redLocationMarker;
    case LEVEL_COLOR.BLUE:
      return blueLocationMarker;
    case LEVEL_COLOR.YELLOW:
      return yellowLocationMarker;
    case LEVEL_COLOR.GRAY:
      return grayLocationMarker;
    default:
      return unknownLocationMarker;
  }
};

export const getColorDot = (color) => {
  switch (color) {
    case LEVEL_COLOR.GREEN:
      return greenDotIconPng;
    case LEVEL_COLOR.RED:
      return redDotIconPng;
    case LEVEL_COLOR.YELLOW:
      return yellowDotIconPng;
    case LEVEL_COLOR.BLUE:
      return blueDotIconPng;
    case LEVEL_COLOR.GRAY:
      return grayDotIconPng;
    default:
      return unknownMarker;
  }
};

export const getCurrentPage = (totalList, pageIndex, rowsPerPage) => {
  const totalCount = totalList?.length || 0;

  // if total count is less than rowsPerPage
  if (totalCount <= rowsPerPage) return totalList;

  const startIndex = rowsPerPage * pageIndex;
  const endIndex = startIndex + rowsPerPage - 1;

  if (startIndex + 1 > totalCount) return [];

  return totalList.slice(startIndex, endIndex + 1);
};

// For now, this only filters array based on string
export const getFilteredList = (totalList, column, searchWord) => {
  if (totalList?.length < 1 || !column || !searchWord) return totalList;

  const lowercaseWord = searchWord.toLowerCase();

  return totalList.filter((item) => (item[column] ? item[column].toLowerCase().includes(lowercaseWord) : false));
};

export const getFilteredListNew = (totalList, column, columnType, searchWord) => {
  if (totalList?.length < 1 || !column || !columnType || searchWord === undefined) return totalList;

  if (columnType === COLUMN_TYPE.STRING) {
    const lowercaseWord = searchWord.toLowerCase();
    return totalList.filter((item) => (item[column] ? item[column].toLowerCase().includes(lowercaseWord) : false));
  }

  if (columnType === COLUMN_TYPE.OPTION) {
    if (typeof searchWord === "string") {
      const lowercaseWord = searchWord.toLowerCase();
      return totalList.filter((item) => (item[column] ? item[column].toLowerCase() === lowercaseWord : false));
    }

    return totalList.filter((item) => item[column] === searchWord);
  }

  if (columnType === COLUMN_TYPE.NUMBER) {
    const word = typeof searchWord === "string" ? parseInt(searchWord, 10) : searchWord;
    return totalList.filter((item) => item[column] === word);
  }

  if (columnType === COLUMN_TYPE.DATE) {
    const { startDate, endDate } = searchWord;

    return totalList.filter(
      (item) => dayjs(item[column]).isAfter(dayjs(startDate)) && dayjs(item[column]).isBefore(dayjs(endDate)),
    );
  }

  return [];
};

export const getSortList = (list, sortBy, sortOrder, sortColumnType) => {
  if (!list?.length || !sortBy || !sortOrder || !sortColumnType) return list;

  const sortFunc = (firstItem, secondItem) => {
    const firstAttribute = firstItem[sortBy];
    const secondAttribute = secondItem[sortBy];

    if (sortColumnType === COLUMN_TYPE.DATE) {
      if (dayjs(firstAttribute).isBefore(dayjs(secondAttribute))) return sortOrder === SORT_DIRECTION.ASC ? -1 : 1;

      if (dayjs(firstAttribute).isAfter(dayjs(secondAttribute))) return sortOrder === SORT_DIRECTION.ASC ? 1 : -1;

      return 0;
    }

    if (sortColumnType === COLUMN_TYPE.NUMBER) {
      if (firstAttribute < secondAttribute) return sortOrder === SORT_DIRECTION.ASC ? -1 : 1;

      if (firstAttribute > secondAttribute) return sortOrder === SORT_DIRECTION.ASC ? 1 : -1;

      return 0;
    }

    if (sortColumnType === COLUMN_TYPE.OPTION && typeof firstAttribute === "boolean") {
      const compareValue = firstAttribute === secondAttribute ? 0 : firstAttribute ? -1 : 1;

      return sortOrder === SORT_DIRECTION.ASC ? compareValue : -compareValue;
    }

    if (sortColumnType === COLUMN_TYPE.STRING || sortColumnType === COLUMN_TYPE.OPTION) {
      if (firstAttribute.toLowerCase() < secondAttribute.toLowerCase())
        return sortOrder === SORT_DIRECTION.ASC ? -1 : 1;

      if (firstAttribute.toLowerCase() > secondAttribute.toLowerCase())
        return sortOrder === SORT_DIRECTION.ASC ? 1 : -1;

      return 0;
    }

    // if Column is other type, just do common compare
    if (firstAttribute < secondAttribute) return sortOrder === SORT_DIRECTION.ASC ? -1 : 1;

    if (firstAttribute > secondAttribute) return sortOrder === SORT_DIRECTION.ASC ? 1 : -1;

    return 0;
  };

  const sorted = [...list];
  sorted.sort(sortFunc);
  return sorted;
};

export const storeActiveSerial = (serialNumber, isChecked = true) => {
  const activeSerialNumbers = window.sessionStorage.getItem("activeDevice");
  const parsedSerial = JSON.parse(activeSerialNumbers);
  let serialNumbers = [];
  if (activeSerialNumbers !== null && activeSerialNumbers !== "") {
    if (isChecked) {
      serialNumbers.unshift(...parsedSerial);
      // eslint-disable-next-line no-undef
      serialNumbers = _.without(serialNumbers, serialNumber);
    } else {
      serialNumbers.unshift(serialNumber, ...parsedSerial);
    }
  } else {
    serialNumbers.unshift(serialNumber);
  }
  const stringifySerials = JSON.stringify(serialNumbers);

  window.sessionStorage.setItem("activeDevice", stringifySerials);
};
