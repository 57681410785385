import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { DisplaySettingsOutlined } from "@mui/icons-material";
import { TreeItem } from "@mui/lab";
import { IconButton, TextField, Typography } from "@mui/material";
import { getDevicePools } from "api/DevicePoolHelpers";
import { setCheckAll, filterCheckedPools } from "api/MappingHelpers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TextButton } from "components/common/Buttons";
import { PageHeader } from "components/common/Wrapper";
import { checkedPoolListArray, DevicePoolDetailTypes, poolData, pageCounter } from "depot/map-page.depot";
import usePrevious from "hooks/usePrevious";
import { Subscription } from "rxjs";
import { CheckableLabel } from "./checkableLabel.component";
import DeviceList from "./device-list.component";
import { KEY_FIELD_IDS, AVAILABLE_KEY_FIELDS } from "./MapSidebar.config";
import { ContentWrapper, DeviceTreeView, Spinner } from "./MapSidebar.style";
import { TreeIconItem } from "./treeIconItem.component";
import style from "./map-side-bar.module.css";

interface MapSidebarProps {
  dataFieldStr: string;
}

export const MapSidebarContent = ({ dataFieldStr }: MapSidebarProps): JSX.Element => {
  const [checkedPoolList, setCheckedPoolList] = useState<string[]>(checkedPoolListArray.data);
  const [keyField, setKeyField] = useState<string>(KEY_FIELD_IDS.SERIAL_NO);
  const prevCheckedPoolList = usePrevious(checkedPoolList);
  const [displayKeyField, setDisplayKeyField] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [poolArray, setPoolArray] = useState<DevicePoolDetailTypes[]>(poolData.data.devicePoolData);
  const [totalDevicePools, setTotalDevicePools] = useState<number>(poolData.data.totalDevicePoolsInOrg);
  const [expandedArray, setExpandedArray] = useState<string[]>([]);

  const getPoolData = async (): Promise<void> => {
    setIsLoading(true);
    const pageCounterData = pageCounter.data;

    await getDevicePools();
    pageCounter.setData(pageCounterData + 1);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!poolArray.length) {
      getPoolData();
    }

    const fetchedDevicePoolData: Subscription = poolData.observable.subscribe({
      next: (data) => {
        setPoolArray(data.devicePoolData);
        setTotalDevicePools(data.totalDevicePoolsInOrg);
      },
    });

    const fetchedCheckedPools: Subscription = checkedPoolListArray.observable.subscribe({
      next: (data) => {
        setCheckedPoolList(data);
      },
    });

    return () => {
      fetchedDevicePoolData.unsubscribe();
      fetchedCheckedPools.unsubscribe();
    };
  }, []);

  const isLoadingMore: boolean = isLoading;

  const isReachingEnd = useMemo(() => {
    if (!poolArray || !poolArray.length) return true;

    return totalDevicePools <= poolArray?.length;
  }, [poolArray]);

  const onKeyFieldChange = (event: BaseSyntheticEvent | undefined) => {
    if (!event) {
      return;
    }
    return setKeyField(event.target.value);
  };

  const getMorePools = () => {
    getPoolData();
  };

  const willTreeExpand = (devicePool: string, isBoxClicked: boolean) => {
    const nodeArray: string[] = expandedArray;
    const treeNodeId: string = `pool-${devicePool}`;

    if (nodeArray.includes(treeNodeId) && !isBoxClicked) {
      const filteredNodeArray = nodeArray.filter((nodeId) => nodeId !== treeNodeId);
      return setExpandedArray(filteredNodeArray);
    }

    nodeArray.push(treeNodeId);

    return setExpandedArray(nodeArray);
  };

  return (
    <ContentWrapper>
      <PageHeader>
        <span className="header-title">Device List</span>
        <IconButton color="primary" onClick={() => setDisplayKeyField(!displayKeyField)}>
          <DisplaySettingsOutlined />
        </IconButton>
      </PageHeader>
      {displayKeyField && (
        <div className={style.formWrapper}>
          <FormControl sx={{ m: 0, width: "100%" }} size="small">
            <TextField
              size="small"
              id="select-key-field"
              className={style.textField}
              label={
                <Typography className={style.textFieldLabel} component="h3">
                  Display Value
                </Typography>
              }
              value={keyField}
              onChange={onKeyFieldChange}
              InputLabelProps={{ style: { fontSize: "14px" } }}
              select
            >
              {AVAILABLE_KEY_FIELDS.map(({ id, label }) => (
                <MenuItem key={`${label}${id}`} className={style.menuItem} value={id}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
      )}
      <DeviceTreeView expanded={expandedArray}>
        {poolArray &&
          poolArray.map(({ devicePool, deviceCount }) => (
            <TreeIconItem
              collapseIcon={deviceCount && <ExpandMoreIcon />}
              expandIcon={deviceCount && <ChevronRightIcon />}
              onClick={() => willTreeExpand(devicePool, false)}
              className="pool-node"
              key={devicePool}
              nodeId={`pool-${devicePool}`}
              label={
                <CheckableLabel
                  title={devicePool}
                  onCheck={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCheckAll(devicePool);
                    filterCheckedPools(e.target.checked, devicePool);
                    willTreeExpand(devicePool, true);
                  }}
                  isChecked={checkedPoolList.includes(devicePool)}
                />
              }
            >
              <DeviceList
                poolId={devicePool}
                dataFieldStr={dataFieldStr}
                prevCheckedPoolList={prevCheckedPoolList}
                keyField={keyField}
              />
            </TreeIconItem>
          ))}

        {isLoadingMore ? (
          <TreeItem icon={false} nodeId={`loading-${Math.random().toString(36).slice(2)}`} label={<Spinner />} />
        ) : isReachingEnd ? (
          <div />
        ) : (
          <div className={style.loadButtonContainer}>
            <TextButton
              sx={{ marginLeft: 0, paddingLeft: 0 }}
              nodeId={`loading-${Math.random().toString(36).slice(2)}`}
              onClick={getMorePools}
              className={style.loadMore}
            >
              Load More...
            </TextButton>
          </div>
        )}
      </DeviceTreeView>
    </ContentWrapper>
  );
};
