export const exportCsv = (dataObj: any[], labels: string[]) => {
  const timeStamp = new Date();

  const csvDataLabels = `${labels}\n`;
  const deviceLocationStrings: string = dataObj.map((row: any) => Object.values(row).join(",")).join("\n");

  const hiddenElement = document.createElement("a");

  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvDataLabels + deviceLocationStrings)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `MegaFi_${timeStamp}.csv`;
  hiddenElement.click();
};
