import React, { useMemo, useEffect, useState } from "react";
import TreeItem from "@mui/lab/TreeItem";
import { isEqual } from "lodash";
import {
  filterCheckedDevices,
  filterCheckedPools,
  getFomattedDeviceData,
  getPoolDeviceList,
  setCheckAll,
} from "api/MappingHelpers";
import { PoolDeviceListTypes, PoolDeviceTypes } from "pages/Pools/pools.config";
import { checkedDeviceListArray, checkedPoolListArray, poolDeviceList } from "depot/map-page.depot";
import { TextButton } from "components/common/Buttons";
import style from "./map-side-bar.module.css";

import { DeviceTreeItem, Spinner } from "./MapSidebar.style";
import { MISSING_DESC, MISSING_DESC_TYPES } from "./MapSidebar.config";
import { DeviceItemLabel } from "./ItemLabel.component";

interface DeviceListProps {
  poolId: string;
  dataFieldStr: string;
  prevCheckedPoolList?: string[] | undefined;
  keyField: string;
}

const DeviceList = ({ poolId, dataFieldStr, prevCheckedPoolList, keyField }: DeviceListProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [poolDeviceArray, setPoolDeviceArray] = useState<PoolDeviceListTypes>(poolDeviceList.data);
  const [checkedDeviceList, setCheckedDeviceList] = useState<string[]>(checkedDeviceListArray.data);
  const [checkedPoolList, setCheckedPoolList] = useState<string[]>(checkedPoolListArray.data);
  const [isEndOfList, setIsEndOfList] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    getPoolDeviceList(poolId);
    setIsLoading(false);

    const checkedDeviceListArrayObs = checkedDeviceListArray.observable.subscribe({
      next: (deviceData) => {
        setCheckedDeviceList(deviceData);
      },
    });

    const checkedPoolListArrayObs = checkedPoolListArray.observable.subscribe({
      next: (deviceData) => {
        setCheckedPoolList(deviceData);
      },
    });

    const poolDeviceListObs = poolDeviceList.observable.subscribe({
      next: (deviceData) => {
        setPoolDeviceArray(deviceData);
      },
    });

    return () => {
      poolDeviceListObs.unsubscribe();
      checkedDeviceListArrayObs.unsubscribe();
      checkedPoolListArrayObs.unsubscribe();
    };
  }, []);

  const isLoadingMore = isLoading;

  const getIsReachingEnd = () => {
    const poolData = poolDeviceList.data[poolId as keyof PoolDeviceListTypes];

    if (!poolData) {
      return;
    }
    const { isReachingEnd } = poolData;

    setIsEndOfList(isReachingEnd);
  };

  useEffect(() => {
    getIsReachingEnd();
  }, [poolDeviceArray]);

  // The data is constantly changing and not being held. When it is empty, it should return empty. When selecting a second pool the data changes to the last clicked
  const deviceArray = useMemo(() => {
    const formattedMapData = getFomattedDeviceData(keyField, poolId);

    return formattedMapData;
  }, [poolDeviceArray, keyField, checkedDeviceList, checkedPoolList]);

  useEffect(() => {
    if (isEqual(checkedPoolList, prevCheckedPoolList)) {
      return;
    }
    if (deviceArray?.length > 0) {
      deviceArray.forEach(({ serialNumber, checked }: { serialNumber: string; checked: boolean }) => {
        filterCheckedDevices(checked, serialNumber);
      });
    }
  }, [checkedPoolList, prevCheckedPoolList, poolDeviceArray]);

  const checkDevicePool = (e: React.ChangeEvent<HTMLInputElement>, serialNumber: string): void => {
    setCheckAll(poolId, false);
    const newDeviceListArr = checkedDeviceList.filter((serial) => {
      return serial !== serialNumber;
    });
    if (e.target.checked) {
      newDeviceListArr.push(serialNumber);
    }

    checkedDeviceListArray.setData(newDeviceListArr);
    filterCheckedPools(false, poolId);
  };

  return (
    <>
      {deviceArray.map((device: PoolDeviceTypes) => {
        const { serialNumber, checked } = device;
        const title: string | boolean | undefined = device[keyField as keyof PoolDeviceTypes];

        return (
          <DeviceTreeItem
            key={serialNumber}
            label={
              <DeviceItemLabel
                title={title}
                serialNumber={serialNumber}
                description={MISSING_DESC[keyField as keyof MISSING_DESC_TYPES]}
                onCheck={(e: React.ChangeEvent<HTMLInputElement>) => {
                  return checkDevicePool(e, serialNumber);
                }}
                isChecked={checked}
                dataFieldStr={dataFieldStr}
              />
            }
            nodeId={`device-${serialNumber}`}
          />
        );
      })}

      {isLoadingMore ? (
        <TreeItem nodeId={`loading-${Math.random().toString(36).slice(2)}`} label={<Spinner />} />
      ) : isEndOfList ? (
        <div />
      ) : (
        <TreeItem
          nodeId={`load-more-${Math.random().toString(36).slice(2)}`}
          label={
            <div className={style.loadButtonContainer}>
              <TextButton
                sx={{ marginLeft: 0, paddingLeft: 0 }}
                nodeId={`loading-${Math.random().toString(36).slice(2)}`}
                onClick={() => {
                  getPoolDeviceList(poolId);
                }}
                className={style.loadMore}
              >
                Load More...
              </TextButton>
            </div>
          }
        />
      )}
    </>
  );
};

export default DeviceList;
