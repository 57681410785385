import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Control, Controller, FieldValues } from "react-hook-form";
import { StyledTextField } from "components/common/Input";
import { TableOptionTypes } from "pages/Devices/Detail/components/form-component/device-detail-form.component";

interface TableSelectProps {
  name: string;
  control: Control<FieldValues, any>;
  options: TableOptionTypes[];
  width: string;
  disabled?: boolean;
}

const TableSelect = ({ name, control, options, width = "100%", ...props }: TableSelectProps): JSX.Element => (
  <Controller
    name={name}
    control={control}
    defaultValue={options?.[0]?.value}
    render={({ field }) => (
      <StyledTextField
        sx={{
          "& .MuiSelect-select": {
            fontSize: "14px",
            paddingRight: 4,
            paddingLeft: 2,
            paddingTop: 1,
            paddingBottom: 1,
          },
        }}
        select
        {...field}
        {...props}
        inputProps={{ style: { padding: 0 } }}
      >
        {options.map(({ value, label }: { value: string; label: string }) => (
          <MenuItem
            sx={{
              fontSize: "14px",
            }}
            key={value}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
      </StyledTextField>
    )}
  />
);

export default TableSelect;
