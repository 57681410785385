import React from "react";
import { getDateString } from "utils/util-functions";
import { COLUMN_TYPE } from "utils/constants";
import { TableColumnTypes } from "pages/configs/configs";
import { GreenStatusDot, StatusDotWrapper } from "./Devices.style";

export interface eiInterfaceTypes {
  availableTypes: string[];
  isEnabled: boolean;
  port: number;
  rxBytes: number;
  txBytes: number;
  type: string;
}

interface eiLocalApiTypes {
  eiRebootIsEnabled: boolean;
  modemPowerCycle: boolean;
  modemStatusIsEnabled: boolean;
}

interface UptimeTypes {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface DeviceDetailDataTypes {
  actionJson: string;
  attempts: number;
  completed: string;
  eiSerialNumber: string;
  maxAttempts: number;
  sequence: number;
}

export interface DeviceValueTypes {
  RxPower: string;
  apn: string;
  availableSW: string[];
  deviceAlias: string;
  deviceDescription: string;
  devicePool: string;
  eiConfigFile: string;
  eiInterfaces: eiInterfaceTypes[];
  eiLocalApis: eiLocalApiTypes;
  eiSerialNumber: string | undefined;
  eiSoftwareVersion: string;
  imei: string;
  lanIpAddress: string;
  lastCheckIn: string;
  locationTrailEnabled: true;
  online: false;
  organization: string;
  phoneNumber: string;
  rxBytes: string;
  ssid: string;
  txBytes: string;
  txPower: string;
  uptime: UptimeTypes;
  userName: string;
  wwanIpAddress: string;
  mac: string;
}

export interface ColumnsOptionTypes {
  label: string;
  // TODO fix value type
  value: any;
}

export interface DeviceColumnTypes extends TableColumnTypes {
  width?: number;
  options?: ColumnsOptionTypes[];
  filter?: boolean;
  isSort?: boolean;
  type?: string;
  format?: (arg: any) => any;
  align?: "center" | "left" | "right" | "inherit" | "justify" | undefined;
  className?: string;
}

const DASHBOARD_COLUMNS: DeviceColumnTypes[] = [
  { id: "", label: "", width: 30 },
  {
    id: "online",
    label: "Status",
    format: (value: string) => <StatusDotWrapper>{value && <GreenStatusDot />}</StatusDotWrapper>,
    filter: true,
    width: 50,
    isSort: true,
    type: COLUMN_TYPE.OPTION,
    options: [
      { value: true, label: "Online" },
      { value: false, label: "Offline" },
    ],
  },
  { id: "eiSerialNumber", label: "Serial No", width: 100, filter: true, isSort: true, type: COLUMN_TYPE.STRING },
  { id: "eiSoftwareVersion", label: "Version", width: 80, filter: true, isSort: true, type: COLUMN_TYPE.STRING },
  {
    id: "imei",
    label: "IMEI",
    width: 110,
    align: "center",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "userName",
    label: "User Name",
    filter: true,
    isSort: true,
    width: 100,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "phoneNumber",
    label: "SIM Phone No.",
    filter: true,
    width: 100,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  { id: "deviceAlias", label: "Alias", width: 30, isSort: true, filter: true, type: COLUMN_TYPE.STRING },
  {
    id: "locationTrailEnabled",
    label: "Location Tracking",
    format: (value: string) => (value ? "Enabled" : "Disabled"),
    filter: true,
    width: 80,
    isSort: true,
    type: COLUMN_TYPE.OPTION,
    options: [
      { value: true, label: "Enabled" },
      { value: false, label: "Disabled" },
    ],
  },
  {
    id: "rxBytes",
    label: "RX Bytes",
    width: 80,
    className: "hide-mobile",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "txBytes",
    label: "TX Bytes",
    width: 80,
    className: "hide-mobile",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
  {
    id: "lastCheckIn",
    label: "Last\u00a0Check\u00a0In",
    width: 180,
    align: "left",
    className: "hide-mobile",
    format: (value: string) => getDateString(value || ""),
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.DATE,
  },
  {
    id: "devicePool",
    label: "Device\u00a0Pool",
    width: 80,
    align: "left",
    className: "hide-mobile",
    filter: true,
    isSort: true,
    type: COLUMN_TYPE.STRING,
  },
];

export default DASHBOARD_COLUMNS;
