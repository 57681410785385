import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const CommonText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #434a54;
`;
export const ErrorText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #d73c23;
`;

export const BoldText = styled(Typography)`
  font-size: 14px;
  color: #434a54;
  font-weight: 500;
`;

export const TitleText = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`;
