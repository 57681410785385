import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Params, useParams } from "react-router-dom";
import EditIconOutlined from "@mui/icons-material/EditOutlined";
import { useTheme } from "@mui/material";
import { FieldValues } from "react-hook-form";
import updateEISoftwareHelper from "api/PendingActionsHelpers";
import { changeDeviceStatusFromUI } from "api/DeviceHelpers";
import { showWarning, showSuccess } from "utils/notifications";
import useConfirm from "hooks/useConfirm";
import TableSelect from "components/table/table-select/table-select.component";
import { FormInput } from "components/common/Input";
import useForm from "components/form/useForm";
import { KeyedMutator } from "swr";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { BoldText, ErrorText } from "components/common/text/text.component";
import LoadingSpinner from "components/LoadingSpinner/loading-spinner.component";
import { useRequest } from "hooks/useRequest";
import { getDateString, nameValidationCheck } from "utils/util-functions";
import { compareSoftwareVersions, isMegaSoftware } from "utils/general";
import { TableRow } from "components/table/table-row/table-row.component";
import { SpinnerWrapper, StatusDot, SectionHeader, DetailGridWrapper } from "./DeviceDetail.style";
import { DeviceValueTypes } from "../../../devices.config";
import style from "./device-detail-form.module.css";

const EI_MIN_UPDATE_VERSION = "EI-2.3.58";
const UPDATING_2_4_96 = "MegaFI-2.4.96";

interface DetailFormProps {
  deviceDetail: DeviceValueTypes;
  mutatePendingActions: KeyedMutator<any>;
  setIsLoadingDetail: (arg: boolean) => void;
  isLoadingDetail: boolean;
  mutateDetail: KeyedMutator<any>;
}

interface FormContentProps extends DetailFormProps {
  isEditing: boolean;
  setIsEditing: (arg: boolean) => void;
}

export interface TableOptionTypes {
  value: string;
  label: string;
}

const FormContent = ({
  deviceDetail,
  mutatePendingActions,
  setIsLoadingDetail,
  isEditing,
  setIsEditing,
  isLoadingDetail,
  mutateDetail,
}: FormContentProps): JSX.Element => {
  const [formData, setFormData] = useState<DeviceValueTypes>(deviceDetail);

  const [isLongUpdate, setIsLongUpdate] = useState(false);
  const [isLongUpdatePopup, setLongUpdatePopup] = useState(false);

  const { data: poolList } = useRequest("devicePools");

  const [getConfirmation, Confirmation] = useConfirm();
  const theme = useTheme();

  const { sn: serialNumber }: Readonly<Params<string>> = useParams();

  const availableVersions = useMemo((): TableOptionTypes[] => {
    if (!formData?.availableSW || !formData?.availableSW?.length) {
      return [];
    }
    const versions = [...formData.availableSW].sort(compareSoftwareVersions);

    return versions.map((version: string) => ({
      value: version,
      label: version,
    }));
  }, [formData]);

  const poolOptions: TableOptionTypes[] = useMemo(() => {
    if (!poolList || !poolList.length) return [];
    return poolList.map(({ devicePool }: { devicePool: TableOptionTypes }) => ({
      value: devicePool,
      label: devicePool,
    }));
  }, [poolList]);

  const uptimeText = useMemo(() => {
    if (!formData.uptime) return "";
    const { days, hours, minutes, seconds } = formData.uptime;
    return [days && `${days}day`, hours && `${hours}h`, minutes && `${minutes}m`, seconds && `${seconds}s`]
      .filter((item) => !!item)
      .join(" ");
  }, [formData.uptime]);

  const saveFormChanges = useCallback(
    async (data: DeviceValueTypes) => {
      setLongUpdatePopup(false);

      const {
        apn: newApn,
        deviceAlias: newDeviceAlias,
        deviceDescription: newDeviceDescription,
        eiSoftwareVersion: newEiSoftwareVersion,
        devicePool: newDevicePool,
      } = data;

      const newDeviceData: any = {};

      // check changed device data
      if (newApn !== formData.apn) newDeviceData.apn = newApn;
      if (newDevicePool !== formData.devicePool) newDeviceData.devicePool = newDevicePool;
      if (newDeviceDescription !== formData.deviceDescription) {
        newDeviceData.deviceDescription = newDeviceDescription;
        formData.deviceDescription = newDeviceDescription;
      }
      if (newDeviceAlias !== formData.deviceAlias) {
        formData.deviceAlias = data.deviceAlias;
        newDeviceData.deviceAlias = newDeviceAlias;
      }

      // if nothing changed
      if (!Object.keys(newDeviceData).length && formData.eiSoftwareVersion === newEiSoftwareVersion) {
        showWarning("Nothing is changed on the form!");
        return null;
      }
      console.warn("newDeviceAlias", newDeviceData.deviceAlias);

      // init form reset data
      let formResetData = {
        ...formData,
      };

      console.warn("formdata1", formData);

      setIsLoadingDetail?.(true);

      if (formData.eiSoftwareVersion !== newEiSoftwareVersion) {
        try {
          const versionResponse = await updateEISoftwareHelper({
            serialNumber: formData.eiSerialNumber,
            softwareVersion: newEiSoftwareVersion,
          });

          const { data: responseData } = versionResponse || {};
          if (responseData) {
            showWarning(
              "Software update in progress. Do not power off your device. Check pending actions below to see when the software update is complete!",
            );
            mutatePendingActions?.();

            // this should be done from API response
            formResetData = { ...formResetData, eiSoftwareVersion: newEiSoftwareVersion };
          }
        } catch (error) {
          // if version API is failed
          setIsLoadingDetail?.(false);
          return formResetData;
        }
      }

      if (Object.keys(newDeviceData).length > 0) {
        console.warn("keys", newDeviceData);

        try {
          const deviceResponse = await changeDeviceStatusFromUI({
            eiSerialNumber: formData.eiSerialNumber,
            ...newDeviceData,
          });

          const { data: responseData }: any = deviceResponse;

          console.warn("formdata", formData);
          console.warn("responsedata", responseData);

          setFormData({
            ...formData,
            ...responseData,
          });

          // refetch device detail
          mutateDetail?.();
          showSuccess("Successfully updated device details!");

          setIsLoadingDetail(false);
          setIsEditing(false);
          return {
            ...formResetData,
            ...data,
          };
        } catch (error) {
          // if Save fails, then revert all changes on the form
          // Error messages will be handled on Axios interceptor
          setIsLoadingDetail(false);
          return formResetData;
        }
      }

      setIsEditing(false);
      setIsLoadingDetail(false);
      return formResetData;
    },
    [formData, getConfirmation, setIsEditing, setIsLoadingDetail, mutatePendingActions, mutateDetail],
  );

  const onCancel = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const { register, control, errors, Form, watch }: FieldValues = useForm({
    defaultValues: {
      eiSoftwareVersion: formData.eiSoftwareVersion,
      deviceAlias: formData.deviceAlias || "",
      deviceDescription: formData.deviceDescription || "",
      apn: formData.apn,
      devicePool: formData?.devicePool,
    },
    isEditing,
    onSave: saveFormChanges,
    onCancel,
  });

  const currentSoftwareVersion = watch("eiSoftwareVersion");

  const checkSoftwareVersion = () => {
    if (currentSoftwareVersion === UPDATING_2_4_96) setLongUpdatePopup(true);
  };

  useEffect(() => {
    if (currentSoftwareVersion === UPDATING_2_4_96) {
      return setIsLongUpdate(true);
    }

    return setIsLongUpdate(false);
  }, [watch("eiSoftwareVersion")]);

  function getDetailValue(value: any, label?: string) {
    if (value === "Nil" || !value || value === "Not Available") {
      return "";
    }

    if (label === "TX Power" || label === "RX Power") {
      return `${value} dBm`;
    }

    return value;
  }

  return (
    <Form
      openPopup={checkSoftwareVersion}
      closePopup={() => setLongUpdatePopup(false)}
      isLongUpdate={isLongUpdate}
      isLongUpdatePopup={isLongUpdatePopup}
      isLoading={isEditing && isLoadingDetail}
    >
      <DetailGridWrapper>
        <TableRow label="Serial No.:" result={serialNumber} />

        <TableRow label="Current Status:" result="">
          <StatusDot
            style={{
              backgroundColor: formData.online ? theme.palette.success.main : theme.palette.error.main,
              marginRight: 5,
            }}
          />
          <BoldText className="results">{formData.online ? "Online" : "Offline"}</BoldText>
        </TableRow>

        <TableRow label="APN:" result={getDetailValue(formData.apn)} />

        <TableRow label="IMEI:" result={getDetailValue(formData.imei)} />

        <TableRow label="Last Checkin:" result={getDateString(formData.lastCheckIn)} />

        <TableRow label="LAN IP Address:" result={getDetailValue(formData.lanIpAddress)} />

        {isEditing ? (
          <div className={style.formContainer}>
            <TableRow label="Device Alias:" result="">
              <FormInput
                {...register("deviceAlias", {
                  validate: {
                    nameValidationCheck,
                  },
                })}
              />
            </TableRow>
            {errors.deviceAlias && <ErrorText className={style.padding}>{errors.deviceAlias.message}</ErrorText>}
          </div>
        ) : (
          <TableRow label="Device Alias" result={getDetailValue(formData.deviceAlias)} />
        )}

        <TableRow label="Uptime:" result={getDetailValue(uptimeText)} />

        <TableRow label="WWAN IP Address:" result={getDetailValue(formData.wwanIpAddress)} />

        {isEditing ? (
          <div className={style.formContainer}>
            <TableRow label="Device Description:" result="">
              <FormInput
                {...register("deviceDescription", {
                  validate: {
                    nameValidationCheck,
                  },
                })}
              />
            </TableRow>
            {errors.deviceDescription && (
              <ErrorText className={style.padding}>{errors.deviceDescription.message}</ErrorText>
            )}
          </div>
        ) : (
          <TableRow label="Device Description:" result={getDetailValue(formData.deviceDescription)} />
        )}

        <TableRow label="SIM Phone Number:" result={getDetailValue(formData.phoneNumber)} />

        <TableRow label="TX Power" result={getDetailValue(formData.txPower, "TX Power")} />

        {isEditing ? (
          <TableRow label="Device Pool:" result="">
            <TableSelect name="devicePool" options={poolOptions} control={control} width="" />
          </TableRow>
        ) : (
          <TableRow label="Device Pool:" result={getDetailValue(formData.devicePool)} />
        )}

        {isEditing ? (
          <TableRow label="Software Version" result="">
            <TableSelect
              name="eiSoftwareVersion"
              options={availableVersions}
              control={control}
              disabled={
                !formData.online &&
                (isMegaSoftware(deviceDetail.eiSoftwareVersion) ||
                  compareSoftwareVersions(deviceDetail.eiSoftwareVersion, EI_MIN_UPDATE_VERSION) > 0)
              }
              width=""
            />
          </TableRow>
        ) : (
          <TableRow label="Software Version" result={getDetailValue(deviceDetail.eiSoftwareVersion)} />
        )}

        <TableRow label="RX Power" result={getDetailValue(formData.RxPower, "RX Power")} />
      </DetailGridWrapper>

      <Confirmation />
    </Form>
  );
};

const DetailForm = ({
  deviceDetail,
  mutatePendingActions,
  setIsLoadingDetail,
  isLoadingDetail,
  mutateDetail,
}: DetailFormProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <SectionHeader>
        <span className="header-title">Device Details</span>

        {!isLoadingDetail && !isEditing && (
          <NxtIconButton onClick={() => setIsEditing(true)} text="Edit" icon={<EditIconOutlined />} />
        )}
      </SectionHeader>

      {deviceDetail && (
        <FormContent
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          setIsLoadingDetail={setIsLoadingDetail}
          isLoadingDetail={isLoadingDetail}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          mutateDetail={mutateDetail}
        />
      )}

      {!isEditing && isLoadingDetail && (
        <SpinnerWrapper>
          <LoadingSpinner />
        </SpinnerWrapper>
      )}
    </>
  );
};

export default DetailForm;
